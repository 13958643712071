import React, { useState, useEffect } from "react";

const Time = () => {
  const calculateTimeLeft = () => {
    const difference =
      +new Date("Nov 1 2021 07:00:00 GMT") -
      +new Date(new Date().toUTCString());
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  useEffect(() => {
    setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  }, []);
  return (
    <>
      <h5 className="dark-title-small text-center">
        To celebrate the launch of Hexowatch version 3.0 we have a special
        promo!
        <span className="text-hexowatch ml-1 mr-1" style={{ fontWeight: 700 }}>
          Save up to 55% off
        </span>
        all our plans and lock in this discounted annual rate for life.
      </h5>
      <div className="d-md-flex mt-2">
        <h5 className="dark-title-small mt-2 countdown-title-new">
          Limited time offer ends in
        </h5>
        {
          <div className="countdown_container">
            <div className="unit">
              <div className="number_container">
                {
                  //@ts-ignore
                  timeLeft.days
                }
              </div>
              <div className="label_container">Days</div>
            </div>
            <div className="unit">
              <div className="number_container">
                {
                  //@ts-ignore
                  timeLeft.hours
                }
              </div>
              <div className="label_container">Hours</div>
            </div>
            <div className="unit">
              <div className="number_container">
                {
                  //@ts-ignore
                  timeLeft.minutes
                }
              </div>
              <div className="label_container">Minutes</div>
            </div>
            <div className="unit">
              <div className="number_container">
                {
                  //@ts-ignore
                  timeLeft.seconds
                }
              </div>
              <div className="label_container">Seconds</div>
            </div>
          </div>
        }
      </div>
    </>
  );
};
export default Time;
